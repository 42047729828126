import { authHeader, router } from '../_helpers';

export const chatService = {
    chat,
    chatByTopic,
};

function chat(user_message, chat_id) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ user_message, chat_id })
    };

    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL 
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid 
        + "/chats"

        // console.log("endpoint", endpoint)
        // console.log("chat_message", user_message)
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function chatByTopic(user_message, chat_id, scid, cid, mid) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify({ user_message, chat_id })
    };

    
    if (!scid) { scid = 0; }
    if (!cid) { cid = 0; }
    if (!mid) { mid = 0; }

    console.log("scid", scid, "cid", cid, "mid", mid)
    
    if (localStorage.getItem('account') === null) {
        router.push("/accounts")
        return Promise.reject("No account selected");
    }

    let uid = JSON.parse(localStorage.getItem('user')).user_id
    let aid = JSON.parse(localStorage.getItem('account')).account_id

    var endpoint = process.env.VUE_APP_API_URL
        + "/api/v1/users/" + uid 
        + "/accounts/" + aid

    if (scid > 0 && cid == 0 && mid == 0) {
        console.log("sending to scorecard chat")
        endpoint += "/scorecards/" + scid
        + "/chat"
    } else if (scid > 0 && cid > 0 && mid == 0) {
        console.log("sending to category chat")
        endpoint += "/scorecards/" + scid
        + "/categories/" + cid
        + "/chat"
    } else if (scid > 0 && cid > 0 && mid > 0) {
        console.log("sending to measure chat")
        endpoint += "/scorecards/" + scid
        + "/categories/" + cid
        + "/measures/" + mid
        + "/chat"
    }

        console.log("endpoint", endpoint)
        console.log("chat_message", user_message)
    
        return fetch(endpoint, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                //location.reload();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

