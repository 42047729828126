<template>
  <div>
      <div style="">
        <div class="dashboard-content">        
          
          <div v-if="chat_messages && chat_messages.length == 0">
            <div class="greeting" style="text-align: center;">
              <strong>{{ greeting_message }}, {{ user.first_name }}! </strong>
            </div>
            <div style="text-align: center;">
              <p>What do you want to know about {{ topic }}?</p>
            </div>
          </div>

          <div class="row" v-if="user && user.first_name" style="margin-left:6px;font-size: .8em;">
            <div class="column" style="max-width: 600px;margin:auto;">
              <!-- I need 4 small boxes in a 2x2 grid that I can put some text into -->
              <div class="row" style="padding:0;" v-if="chat_messages && chat_messages.length < 1">
                <div class="column">
                  <div class="dashcard" style="padding:10px;">
                    <div style="text-align:center;">
                      <a href="#" v-on:click.prevent="clickChat('Help me understand this topic')">Help me understand this topic</a>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="dashcard" style="padding:10px;">
                    <div style="text-align:center;">
                      <a href="#" v-on:click.prevent="clickChat('Help me get started')">Help me get started</a>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="dashcard" style="padding:10px;">
                    <div style="text-align:center;">
                      <a href="#" v-on:click.prevent="clickChat('What is the status?')">What is the status?</a>
                    </div>
                  </div>
                </div>
                <div class="column">
                  <div class="dashcard" style="padding:10px;">
                    <div style="text-align:center;">
                      <a href="#" v-on:click.prevent="clickChat('What is falling behind?')">What is falling behind?</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- If chat has objects, loop through them here -->
          <div class="row" v-if="chat_messages && chat_messages.length > 0" style="max-width: 800px;margin:auto;">
            <div class="column">
              <div class="chatbox">
                <div v-for="(msg, index) in chat_messages" :key="index" :class="msg.sender
                ? 'chatmsg user'

                : 'chatmsg bot'">
                  <div v-if="msg.sender === 'user'" style="text-align:left;margin-bottom:40px;">
                    <div style="font-weight: bold;">
                      <div v-if="user.profile_image" :style="'border-radius:50%;width:30px;height:30px;background:url(https://corl8-files.s3.amazonaws.com/' + user.profile_image +') no-repeat;background-size:cover;display:inline-block;vertical-align:middle;margin-right:8px;'"></div>
                      <div v-else style="border-radius:50%;width:30px;height:30px;display:inline-block;vertical-align:middle;margin-right:10px;" >
                        <img style="width:30px;height:30px;" :src="require('@/assets/ic_person.png')" alt="profile icon" />
                      </div>
                      <div style="display:inline-block;vertical-align:middle;">
                        {{ user.first_name }}
                      </div>
                    </div>
                    <div style="padding:10px 0 0 40px;font-size:smaller;" class="scaps">
                      {{ msg.text }}
                    </div>
                  </div>
                  <div v-else style="text-align:left;margin-bottom:40px;">
                    <div style="font-weight: bold;">
                      <div style="width:30px;height:30px;display:inline-block;vertical-align:middle;text-align:center;padding:2px;margin-right:10px;" >
                        <img style="transform: rotateY(0deg) rotate(135deg);border-radius:50%;width:26px;height:26px;" :src="require('@/assets/foclarity-icon.png')" alt="Foclarity AI" />
                      </div>
                      <div style="display:inline-block;vertical-align:middle;" >
                        Foclarity.AI
                      </div>
                    </div>
                    <div style="padding:10px 0 0 40px;font-size:smaller;">
                      <div v-html="markedup(msg.text)"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <!-- I need one big div that stays at the bottom of the screen and holds my chat input field -->
          <div class="row" style="max-width: 800px;margin:auto;">
            <div class="column" >
              <div class="chatinput">
                <div style="float:right;height: 0;top:8px;">
                  <!-- add the submit icon -->
                  <img :src="require('@/assets/foclarity-icon.png')" @click="clickChat(chatmsg)" style="width:28px;height:28px;text-align:left;border-radius: 50%;margin-right:8px;cursor:pointer;transform: rotateY(0deg) rotate(135deg);" />
                </div>
                <textarea ref="chatBox" type="text" @disabled="chatmsg && chatmsg.length > 0" placeholder="Message Foclarity AI..." v-model="chatmsg" @keyup.enter="clickChat(chatmsg)" enterkeyhint="send" style="padding-right:40px;" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  import { chatService } from '../../_services';
  import marked from 'marked' ;

  export default {
    props: [
      'user',
      'topic',
      'scid',
      'cid',
      'mid',
    ],
    data () {
        return {
            message: "",
            greeting_message: 'Hello',
            error: "",
            emsg: "",
            submitted: false,
            chatID: 0,
            chatmsg: "",
            chat_messages: [],
        }
      },
    methods: {
        clickChat: function (msg) {
        // console.log(this.chatID);
        this.chatmsg = "Analyzing..."
        this.chat_messages.push({ text: msg, sender: 'user' });

        if (this.chatID == 0) {
            // first chat message
            chatService.chatByTopic(msg, 0, this.scid, this.cid, this.mid).then(
            data => {
                // console.log(data);
                this.chat_messages.push({ text: data.chat_message.bot_message, sender: 'bot' });
                this.chatID = data.chat_message.chat_id;
                this.chatmsg = ""
                // this.scrollToBottom();
            },
            error => {
                // console.log(error);
                this.chat_messages.push({ text: "I'm sorry, there was an error: " + error, sender: 'bot' });
                this.chatmsg = ""
            }
            )
        } else {
            // subsequent chat messages
            chatService.chat(msg, this.chatID).then(
            data => {
                // console.log("has id: " + data.chat_message.chat_id);
                this.chat_messages.push({ text: data.chat_message.bot_message, sender: 'bot' });
                this.chatID = data.chat_message.chat_id;
                this.chatmsg = ""
                // this.scrollToBottom();
            },
            error => {
                this.chat_messages.push({ text: "I'm sorry, there was an error: " + error, sender: 'bot' });
                this.chatmsg = ""

            })
        }
        },
        markedup: function (text) {
        return marked(text)
        },
      },
      created () {
      },
  }
  </script>
  