<template>
  <div class="content">
    <div class="middled">
      
      <div class="row searchrow">
        <div class="column">
          <div class="breadcrumbs">
            <router-link class="small-hider" :to="{ name: 'dashboards'}">{{ account.account_name }}</router-link>
            > <router-link :to="{ name: 'scorecard', params: { scorecard_id: $route.params.scorecard_id }}">{{ scorecard.scorecard_name }}</router-link>
            > <router-link :to="{ name: 'category', params: { scorecard_id: $route.params.scorecard_id, category_id: $route.params.category_id }}">{{ measure.category_name }}</router-link>
            >
          </div>
        </div>
        <div class="column">
          <searchbar />
        </div>
      </div>

      <div v-show="loading" style="text-align:center;">Loading...</div>
      <div v-show="!loading">

        <div class="row">
          <div class="column">
            <div style="display:flex;justify-content:space-between;padding:0px 0 10px 0;">
              <div style="width:auto;white-space: nowrap;">
                <img v-show="prev" v-on:click="prevActivity" style="cursor:pointer;padding: 5px 0;height:40px;transform: rotate(180deg);" :src="require('@/assets/ic_arrow_go.png')" alt="Previous Tile" />
              </div>
              <div style="padding:2px 4px;text-align: center;">
                <router-link :to="{ name: 'category', params: { scorecard_id: $route.params.scorecard_id, category_id: $route.params.category_id }}">
                  <strong v-if="measure.category_name" v-show="measure.category_name">{{ measure.category_name | truncate(100, '...')}}</strong>
                </router-link>
                > <strong>{{ measure.measure_name | truncate(100, '...') }}</strong>
              </div>
              <div style="width:auto;white-space: nowrap;">
                <img v-show="next" v-on:click="nextActivity" style="cursor:pointer;padding: 5px 0;height:40px;" :src="require('@/assets/ic_arrow_go.png')" alt="Next Tile" />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="column measurecontent">
            <div>
              <div class="column rank0" :class="'rank' + snapshot.rank_score">
                <div class="row" :class="'rank' + snapshot.rank_score">
                  <div class="ranknum">{{ snapshot.rank_score ? snapshot.rank_score : '0' }}</div>
                  <div class="rankname">{{ snapshot.benchmark_description ? snapshot.benchmark_description : 'No Status' }}</div>
                  <div class="tiny" style="margin-left:auto;background: #fff;padding:5px 8px;border-radius: 4px;">
                    <span>Owner: </span>
                      <span v-if="measure_owners">
                        <a href="#" v-for="(mo, index) in measure_owners" :key="index" v-on:click.prevent="changeOwner" > {{ mo.owner_first_name + " " + mo.owner_last_name }} </a>
                      </span>
                      <span v-else>
                        <a href="#" v-on:click.prevent="changeOwner">
                          Unassigned
                        </a>
                      </span>
                  </div>
                </div>
  
                <div style="border:1px solid #fff; background:#fff;padding:0 10px;margin:4px 0 0px 0 ;">
                  <div class="row" style="border-bottom: 1px solid #ccc;margin-bottom:10px;">
                    <div style="max-width: 90%;">
                      <h2 @click="showModal = true" style="cursor: pointer;">{{ measure.measure_name }}</h2>
                      <div v-if="measure.measure_description" @click="showModal = true" style="cursor: pointer;margin: 4px 0;overflow:hidden;" class="tiny">{{ measure.measure_description | truncate(120, '... Read all') }}</div>
                    </div>
                    <div class="details_icon">
                      <img :src="require('@/assets/ic_sparkle.png')" width="20" height="20" alt="AI Chat" style="margin:8px;cursor: pointer;" v-on:click="showChat = true" title="Chat with AI" />
                    </div>
                  </div>
                  
                  <div>
                    <div style="padding:10px;background: #eee;border-radius: 4px;" v-if="snapshot.snapshot_notes">
                      <div class="tiny">
                        <span style="font-weight: bold;" v-if="snapshot.user_name">{{ snapshot.user_name  }}</span>
                        {{ snapshot.created_at | moment("MMM DD, YYYY") }}</div>
                      <div style="padding: 10px 0 ;white-space: break-spaces;overflow:hidden;">{{snapshot.snapshot_notes}}</div>
                      <div class="tiny" v-if="snapshots && snapshots.length > 1"><a href="javascript:document.getElementById('commentlist').scrollIntoView(true);">{{ snapshots.length }} updates</a></div>
                    </div>
                  </div>


                  <div style="padding-top:20px;" v-show="benchmarks && benchmarks.length > 0">
                    <div style="text-align: center;margin:20px;" v-show="editStatus">
                      <button style="padding:10px 20px;" v-on:click="editStatus = true">Add Notes</button>
                    </div>

                    <div class="row" v-if="1==2">
                      <div class="column">
                        <iframe src="https://docs.google.com/presentation/d/e/2PACX-1vT0epQfQ4yuUaVicTQyBeSx59N6ufL5NJaE1qxefP6jb82yVTYm4iKbwvFQpaIsxHHz13SSIqL6dubY/embed?start=false&loop=false&delayms=3000" frameborder="0" width="100%" height="400" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                      </div>
                    </div>

                    <div v-show="!editStatus" style="margin-top:20px;">
                      <div class="row" style="width:100%;">
                        <div style="width:100%;">
                          <div style="margin:auto;text-align:center" v-show="!mustsave">
                            <button style="padding:10px 16px;" v-on:click="mustsave=true;$refs.snapshot_notes.focus();">Update Status</button>
                          </div>
                          <div class="row" style="justify-content: left;">
                            <div v-for="benchmark in benchmarks" :key="benchmark.benchmark_id" v-show="mustsave">
                              <div class="benchmark" v-on:click="new_bmid = benchmark.benchmark_id;new_score = benchmark.rank_id;snapshot.rank_score = benchmark.rank_id;snapshot.benchmark_description = benchmark.benchmark_description;mustsave = true;" style="cursor:pointer;">
                                <div class="rank_btn" :class="'rank' + benchmark.rank_id" :title="benchmark.benchmark_description">
                                  {{ benchmark.benchmark_description }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <textarea v-model="snapshot_notes" name="snapshot_notes" ref="snapshot_notes" placeholder="Add status update:" @focus="mustsave = true;" v-show="mustsave" />
                          <div style="font-size:14px;padding:8px;font-weight: bold;">
                            <a href="#" v-on:click.prevent="saveScore(new_bmid)" v-show="mustsave">SAVE</a>
                            <router-link style="float:right;" :to="{ name: 'category', params: { scorecard_id: $route.params.scorecard_id, category_id: $route.params.category_id }}" v-show="mustsave">CANCEL</router-link>
                          </div>
                          <div v-show="error">{{ error }}</div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>          

          <div class="column" v-if="measure">
            <TaskList 
              v-bind:summary="false"
              v-bind:scid="scid" 
              v-bind:cid="cid" 
              v-bind:mid="mid"
              v-bind:key="mloaded"
            />

            <div v-if="tips && tips.length > 0" style="padding:0 20px;background:#eee;">
              <div class="column">
                <h2>Foclarity AI Suggestions:</h2>
                <div class="tiny">Click a suggestion to convert it into a task.</div>
                <div class="row" v-for="(tip, index) in tips" :key="tip.number">
                  <p>
                    <strong>{{ tip.number }}.</strong> <a v-on:click.prevent="maketask(tip.item, index)">{{ tip.item }}</a>
                  </p>
                </div>
                <p class="tiny">(Foclarity AI uses OpenAI GPT-4o)</p>
              </div>
            </div>
            <div v-else style="text-align: center;padding:20px;">
              <div v-if="!tips_waiting">
                <p v-if="tips && tips.length == 0 && tips_error">{{ tips_error }}</p>
                <a v-on:click.prevent="getMeasureTips" :disabled="tips_waiting" style="padding:10px 20px;">AI Task Ideas</a>
                /
                <a v-on:click.prevent="showChat = true" style="padding:10px 20px;">AI Chat</a>
              </div>
              <div v-else>
                <p>{{ tips_waiting }}</p>
              </div>
              <p class="tiny">(Beta / AI GPT-4o)</p>
            </div>
          </div>
        </div>

        <div v-show="false">

          <!-- add div for discussion using measure.measure_comments -->
          <h2 style="border-bottom:1px solid #ccc;">Discussion:</h2>
          <div class="row" style="padding:10px 0;">
            <div class="column" style="max-height: 400px;overflow: auto;flex-direction: column-reverse;">
              <div class="row" v-for="(comment, index) in measure.measure_comments" :key="comment.measure_comment_id">
                <div class="column" style="border-top:0px solid #ccc;">
                  <div class="snapshot">
                    <div class="desc" style="background:#eee;border-radius: 4px;padding:8px;">
                      <div v-if="comment.user_id === user.user_id" class="tiny" style="float:right;">
                        <a href="#" v-on:click.prevent="editComment(comment.measure_comment_id, index)">Edit</a> |
                        <a href="#" v-on:click.prevent="deleteComment(comment.measure_comment_id, index)">Archive</a>
                      </div>
                      <div style="color:#666;" class="row tiny">
                        <div><strong style="color:#000;">{{ comment.user_first_name }}</strong> <span class="tiny">{{ comment.created_at | moment("MMM DD, YYYY") }}</span></div>
                      </div> 
                      <div style="padding:4px 0;">
                        <span style="white-space: break-spaces;" v-html="comment.measure_comment" v-linkified />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- add comment -->
          <div class="row" style="padding:10px 0;">
            <button class="btn btn-primary" style="padding:10px 20px;" :disabled="loading" @click="newComment()">New Comment</button>
          </div>
        </div>

        <div class="row" id="commentlist">
          <div class="column">
            <h2>Status History:</h2>
            <div class="row" v-for="snapshot in snapshots" :key="snapshot.snapshot_id">
                <div class="column" style="border-top:1px solid #ccc;">
                    <div class="snapshot">
                      <div class="desc">
                          <div style="color:#666;" class="row tiny">
                            <div><strong>{{ snapshot.user_name }}</strong> <span class="tiny">{{ snapshot.created_at | moment("MMM DD, YYYY") }}</span></div>
                            <div style="margin-left:auto;">{{ getStatusText(snapshot.rank_score) }}</div>
                          </div> 
                          <div v-if="snapshot.snapshot_notes" style="padding:10px 0;">
                            <span style="white-space: break-spaces;" v-html="snapshot.snapshot_notes" v-linkified />
                          </div>
                          <div v-else style="padding:10px 0;">
                              {{ snapshot.benchmark_description }}
                          </div>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>

      <modal v-if="showChat" @close="showChat = false">
        <template v-slot:header>
          <img :src="require('@/assets/ic_sparkle.png')" width="20" height="20" alt="AI Chat" title="AI Chat" style="margin:8px;" />
        </template>
        <template v-slot:body>
          <ChatByTopic :scid="scid" :cid="cid" :mid="mid" :user="user" :account="account" :topic="measure.measure_name" />
        </template>
        <template v-slot:footer>
          <div style="text-align: center;" class="tiny">Beta v1.3 - Powered by GPT-4o</div>
        </template>
      </modal>

      <!-- use the modal component, pass in the prop -->
      <modal v-if="showModal" @close="showModal = false">
        <template v-slot:header>
          <h3>{{ measure.measure_name }}</h3>
        </template>
        <template v-slot:body>
          <p v-if="measure.measure_description" v-html="measure.measure_description" v-linkified style="white-space:break-spaces;overflow: hidden;" />
          <p v-else>No description</p>
        </template>
        <template v-slot:footer>
          <p style="padding:20px;">
            <a v-if="(account.account_id === scorecard.account_id)" @click.prevent="showEdit = true;showModal=false;" title="Edit" style="float:right;">Edit</a>
          </p>
        </template>
      </modal>

      <!-- use the modal component, pass in the prop -->
      <modal v-if="showEdit" @close="showEdit = false">
        <template v-slot:header>
          <h3>Edit Tile</h3>
        </template>
        <template v-slot:body>
          <div class="form-grp">
            <label for="mname">Tile Name</label>
            <input id="mname" type="text" v-model="measure.measure_name" name="measure_name" maxlength="144" />
          </div>
          <div class="form-grp">
              <label for="mdesc">Description</label>
              <textarea id="mdesc" v-model="measure.measure_description" name="measure_description" />
          </div>
          <div class="form-group">
              <button class="btn btn-primary" :disabled="loading" @click="editMeasure">Save</button>
              |
              <a href="#" @click.prevent="showEdit = false" style="text-decoration:none;">Cancel</a>
          </div>
        </template>
        <template v-slot:footer>
          <div v-if="editmsg">{{editmsg}}</div>
          <div class="tiny" style="text-align: right;" v-else>{{ scorecard.scorecard_name }} / {{ measure.category_name }}</div>
        </template>
      </modal>

      <!-- use the modal component, pass in the prop -->
      <modal v-if="edit_owner" @close="edit_owner = false">
        <template v-slot:header>
          <h3>Ownership</h3>
        </template>
        <template v-slot:body>

          <div class="row">
              <div class="column">
                  <div class="custom-select">
                      <select v-model='edit_own_id'>
                          <option value="0" disabled selected>Choose a Team Member</option>
                          <option
                          v-for='(member, index) in members'
                          :value='member.member_id'
                          :key='index'>
                          {{ member.member_first_name + " " + member.member_last_name + " - " + member.member_email }}
                          </option>
                      </select>
                  </div>
              </div>
          </div>
          <div class="row">
            <button class="btn-add" style="width:100px;" :disabled="loading" v-on:click="updateOwner">Save</button>
          </div>
          <div class="row">
              {{ error }}
          </div>

        </template>
        <template v-slot:footer>
          <div v-if="editmsg">{{editmsg}}</div>
          <div v-else>Save your changes or click "Close" to cancel</div>
        </template>
      </modal>

    </div>
  </div>
</template>

<script>
import { isLoggedIn } from "../utils/auth";
import { accountService, measureOwnerService, snapshotService, taskService, userService } from '../_services';
import { scorecardService } from "../_services/scorecard.service"
import TaskList from "@/components/includes/TaskList"
import ChatByTopic from "@/components/includes/ChatByTopic"
import modal from "@/components/includes/InfoModal"
import moment from 'moment'
import searchbar from "@/components/includes/SearchBar"

export default {
  title: 'Measure | Foclarity',
  name: "Measure",
  mounted: function() {
    if (isLoggedIn()) {
      // this.$router.push("dashboards");
    }
  },
  data () {
      return {
        user: '',
        account: '',
        members: [],
        scorecard: '',
        new_score: '',
        new_bmid: '',
        mustsave: false,
        category: '',
        measure: '',
        tips: [],
        tips_waiting: "",
        tips_error: "",
        mloaded: 0,
        next: true,
        prev: true,
        next_id: -1,
        prev_id: -1,
        scid: this.$route.params.scorecard_id,
        cid: this.$route.params.category_id,
        mid: this.$route.params.measure_id,
        benchmarks: [],
        edit_owner: false,
        measure_owners: [],
        edit_own_id: 0,
        measures: [],
        snapshot: {
          rank_score: 1,
        },
        snapshot_notes: '',
        showSaveButton: 0,
        snapshots: [],
        series: [{name: "Score", data: [0]}],
        mkey: 0,
        message: '',
        emsg: '',
        submitted: false,
        loading: true,
        showModal: false,
        showChat: false,
        toggle: false,
        showEdit: false,
        editmsg: '',
        returnUrl: '',
        error: '',
        updatehistory: 0,
        editStatus: false,
        showFullDescription: false,
        report: ''
      }
  },
  methods: {
    isLoggedIn() {
      return isLoggedIn();
    },
    editMeasure: function () {
        //console.log("editing")
        scorecardService.updateMeasure(this.scid, this.cid, this.mid, this.measure.measure_name, this.measure.measure_description)
            .then(
                data => {
                    this.editmsg = data.measure_name + ' Saved. Close this window to continue.';
                    this.showEdit = false ;
                },
                error => {
                    this.editmsg = error + ' Please close and try again.'
                }
            );
    },
    changeOwner: function () {
      accountService.readAccountMembers().then(
        data => {
          this.members = data.members
          // console.log(this.members)
          if (this.measure_owners) {
            this.edit_own_id = this.measure_owners[0].owner_id
          }
          this.edit_owner = true
        }
      )
    },
    maketask: function (td, index) {
      if(confirm("Convert this to a task? " + td)){
        var newDate = new Date()
        // set new date to 7 days from now
        newDate.setDate(newDate.getDate() + 7)
        var dateValue = moment(newDate)
        const duby = dateValue.format()
        const uid = this.user.user_id
        taskService.createMeasureTask(this.scid, this.cid, this.mid, td, false, uid, duby).then(
          data => {
            if (data.status == "success") {
              this.tips.splice([index],1)
              this.mloaded ++
            } else {
              alert("Ran into an issue converting to task")
            }
          }
        )
      }
    },
    newComment: function () {
      let new_comment = prompt("Enter your comment")
      if (new_comment) {
        scorecardService.createMeasureComment(this.scid, this.cid, this.mid, new_comment).then(
          data => {
            if (data.status == "success") {
              data.measure_comment.user_first_name = this.user.first_name
              this.measure.measure_comments.unshift(data.measure_comment)
            } else {
              alert("Ran into an issue adding comment")
            }
          }
        )
      }
    },
    editComment: function (cid, index) {
      let new_comment = prompt("Edit your comment", this.measure.measure_comments[index].measure_comment)
      if (new_comment) {
        scorecardService.updateMeasureComment(this.scid, this.cid, this.mid, cid, new_comment).then(
          data => {
            if (data.status == "success") {
              this.measure.measure_comments[index].measure_comment = new_comment
            } else {
              alert("Ran into an issue editing comment")
            }
          }
        )
      }
    },
    deleteComment: function (cid, index) {
      if(confirm("Archive this comment?")){
        scorecardService.deleteMeasureComment(this.scid, this.cid, this.mid, cid).then(
          data => {
            if (data.status == "success") {
              this.measure.measure_comments.splice(index, 1)
            } else {
              alert("Ran into an issue archiving comment")
            }
          }
        )
      }
    },
    updateOwner: function () {
      measureOwnerService.addMeasureOwner(this.scid, this.cid, this.mid, this.edit_own_id)
        .then(
          data => {
            if (data.owner_id) {
              this.measure_owners = []
              this.measure_owners.push(data)
              this.measure.measure_owners = this.measure_owners
              this.edit_owner = false ;
            } else {
              alert(data)
              this.edit_owner = false ;
            }
          },
          error => {
            console.log(error)
          }
        );
    },
    activeAccount() {
      // console.log("getting active account")
      this.account = accountService.getActiveAccount() ;
      this.user = userService.getActiveUser() ;
    },
    construction: () => {
      alert('You are still ahead of me... We are working on this feature. Stay tuned!')
    },
    containsKey(obj, key ) {
      return Object.keys(obj).includes(key);
    },
    gotoBenchmark: function (bid) {
      this.$router.push("/scorecards/"+this.scid+"/categories/"+this.cid+"/measures/"+this.mid+"/benchmarks/"+bid)
    },
    saveScore: function (bmid) {
      const notes = this.snapshot_notes
      // console.log(bmid)
      if (bmid < 1) {
        alert("Please select a Progress color before you save.");
        return;
      }
      if ((bmid != this.snapshot.benchmark_id) || this.snapshot_notes != "") {
        snapshotService.createSnapshot(this.scid, this.cid, this.mid, bmid, notes).then(
        data => {
            if (data.status == 'failed') {
                this.error = 'failed to save, please try again.' ;
            } else {
                // console.log(data)
                this.snapshot.benchmark_id = bmid
                this.error = ''
                this.snapshot_notes = ""
                this.snapshot.snapshot_notes = notes
                this.showSaveButton = 0
                this.snapshots.push(data)
                this.updatehistory++
                this.getMeasureSnapshot();
                this.editStatus = false ;
                this.mustsave = false;
            }
        },
        error => {
          this.error = error
        });
      } else {
        //console.debug('already saved this score')
        this.error = 'Already saved this status, try again.'
      }
    },
    nextActivity() {
      window.location.href= `/scorecards/${this.scid}/categories/${this.cid}/measures/${this.next_id}`
    },
    prevActivity() {
      window.location.href= `/scorecards/${this.scid}/categories/${this.cid}/measures/${this.prev_id}`
    },
    loadMeasures: function () {
      // console.log("loading measures")
      scorecardService.getMeasuresForCategory(this.scid, this.cid).then(
        data => {
          this.measures = data.measures
          this.measures.forEach((measure, index) => {
            // console.log(measure.measure_id, index)
            if (measure.measure_id == this.mid) {
              if ((index - 1) >= 0) {
                this.next_id = this.measures[index - 1].measure_id ;
              }
              if ((index + 1) < this.measures.length) {
                this.prev_id = this.measures[index + 1].measure_id ;
              }
            }
          })

          if (this.prev_id < 0) {
            this.prev = false ;
          }

          if (this.next_id < 0) {
            this.next = false ;
          }            
          this.loading = false
        },
        error => {
          //console.debug(error)
          this.error = error
          this.loading = false
        }
      )
    },
    contactSupport: function () {
      const subj = "Button clicked on " + this.measure.measure_name
      const msg = "Clicked the button on " + this.measure.measure_name
      this.submitted = true ;

      accountService.contactSupport(subj, msg).then(
        data => {
          if (data.status == 'failed') {
            this.submitted = false ;
            this.error = 'Thank you for testing' ;
          } else {
            this.error = ""
            this.message = ""
            this.submitted = false ;
            this.emsg = "This feature is not available yet. Email support@foclarity.com for help."
          }
      },
      error => {
        this.error = error
        this.loading = false
      });
    },
    getMeasureSnapshots: function () {
      // console.log("getting measure snapshots")
      snapshotService.readMeasureSnapshots(this.scid, this.cid, this.mid).then(
        data => {
          this.snapshots = data.snapshots
          var scores = []
          this.snapshots.forEach(snapshot => {
            scores.push(snapshot.rank_score)
          });
          scores.push(0);
          scores.reverse();
          const s1 = [{
                name: "Score",
                data: scores
            }]
          this.series = s1 ;
          this.mkey++ ;
          this.loading = false
        },
        error => {
          //console.debug(error)
          this.error = error
          this.loading = false
        }
      )
    },
    activeScorecard: function () {
      // console.log("getting active scorecard")
      this.scid = this.$route.params.scorecard_id
      scorecardService.readScorecard(this.scid).then(
        data => {
          this.scorecard = data.scorecard
        },
        error => {
          this.error = error
          this.loading = false
        }
      )
    },
    getMeasure: function () {
      // console.log("getting measure")
      this.loading = true
      scorecardService.switchMeasure(this.scid, this.cid, this.mid).then(
          data => {
            this.measure = data.measure
            window.document.title = this.measure.measure_name + " | Workspace"
            this.benchmarks = data.measure.benchmarks
            this.measure_owners = data.measure.measure_owners
            this.loading = false
        },
        error => {
          this.error = error
          this.loading = false
        }
        )
    },
    getStatusText: function(num) {
      if (num === 1) {
        return "On Hold"
      } else if (num === 2) {
        return "Behind"
      } else if (num === 3) {
        return "On Track"
      } else if (num === 4) {
        return "Almost Done"
      } else if (num === 5) {
        return "Completed"
      } else {
        return ""
      }
    },
    getMeasureSnapshot: function () {
      // console.log("getting measure snapshot")
      snapshotService.readBenchmarkSnapshot(this.scid, this.cid, this.mid).then(
        data => {
          if (data.snapshot.rank_score === undefined) {
            this.snapshot = {
              rank_score: 0
            }
          } else {
            this.snapshot = data.snapshot
            this.new_score = data.snapshot.rank_score
            this.new_bmid = data.snapshot.benchmark_id
          }
          this.loading = false
        },
        error => {
          // console.log(error)
          this.error = error
          this.loading = false
        }
      )
    },
    convertNumberedListToJSON: function(numberedListString) {
      // Split the string into individual lines
      if (numberedListString === undefined || numberedListString.length == 0) {
        return []
      }
      const lines = numberedListString.trim().split('\n');

      // Extract the numbers and items from each line
      const items = [];
      lines.forEach((line) => {
        const [number, item] = line.split('. ');
        if (item !== undefined) {
          items.push({ number: parseInt(number), item: item.trim() });
        }
      });

      // Convert the list to a JSON array
      // const jsonArray = JSON.stringify(items);

      // return jsonArray;
      return items ;
    },    
    getMeasureTips: function () {
      if(confirm("Click OK to Generate AI suggestions. NOTE: Please limit requests while we are in beta, and send us feedback.")){
        this.tips_waiting = "Please wait... generating suggestions"
        this.tips_error = ""
        accountService.measureTips(this.scid, this.cid, this.mid).then(
          data => {
            if (data.status === 'success') {
              if (data.tips === undefined || data.tips.length == 0) {
                this.tips = []
                this.tips_error = "No tips found. Please try again."
              } else {
                var raw_content = data.tips
                this.tips = this.convertNumberedListToJSON(raw_content)
                //console.log(this.tips)
                this.tips_error = "" ;
              }
              this.tips_waiting = "" ;
            } else {
              this.tips = [] ;
              this.tips_error = data.message ;
            }
          },
          error => {
            this.tips = []
            this.tips_error = error
          }
        )
      }
    }
  },
  components: {
    TaskList,
    modal,
    searchbar,
    ChatByTopic,
  },
  watch: {
    updatehistory: function () {
      this.getMeasureSnapshots() ;
    },
    $route(to) {
      this.scid = to.params.scorecard_id
      this.cid = to.params.category_id
      this.mid = to.params.measure_id
      this.mloaded ++ ;
      this.activeAccount() ;
      this.getMeasure();
      this.loadMeasures() ;
      this.getMeasureSnapshot();
      this.getMeasureSnapshots();
      this.activeScorecard() ;
    }
  },
  created () {
    this.activeAccount() ;
    this.getMeasure();
    this.loadMeasures() ;
    this.getMeasureSnapshot();
    this.getMeasureSnapshots();
    this.activeScorecard() ;
  },
  computed: {
    cardcolor: function () {
      var score = this.snapshot.rank_score 
      if (score < 1.5) {
        return {'emerging': true}
      } else if (score >= 1.5 && score < 2.5) {
        return {'below-average': true}
      } else if (score >= 2.5 && score < 3.5) {
        return {'average': true}
      } else if (score >= 3.5 && score < 4.5) {
        return {'above-average': true}
      } else if (score >= 4.5) {
        return {'leadership': true}
      } else {
        return 'emerging'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.snapshot-score {
  width: 120px ;
  height: 120px ;
  text-align: center ;
  margin:auto ;
  font-size: 5.4rem ;
  line-height: 7rem ;
  border-radius: 60px ;
  background-color: #0066CC;
  color: #fff ;
  // border: 4px solid #ccc ;
}

.emerging {
  background: #b30000 !important ;
  color: #fff ;
  & div > h1 {
    color: #fff ;
  }
}
.below-average {
  background: #CC0000 !important ;
  color: #fff ;
  & div > h1 {
    color: #fff ;
  }
}
.average {
  background: #cca300 !important ;
  color: #fff ;
  & div > h1 {
    color: #fff ;
  }
}
.above-average {
  background: #669900 !important ;
  color: #fff ;
  & div > h1 {
    color: #fff ;
  }
}
.leadership {
  background: #558000 !important ;
  color: #fff ;
  & div > h1 {
    color: #fff ;
  }
}

.column a {
  text-decoration: none ;
}
.snapshot {
  font-weight: 400 ;
  font-size: 1.2rem;
  line-height: 1.75rem;
  letter-spacing: .5px;
}
.rank_btn {
  flex: none ;
  margin: 4px ;
  text-align: center ;
  font-size: 12px ;
  font-weight: bold;
  line-height: 23px ;
  width: 85px ;
  height: 25px ;
  border-radius: 25px ;
  color: #fff;
  &:hover{
    background:#000;
    color:#fff;
  }
}
.benchmark {
    text-align: left ;
    display: flex ;
    .rank {
      flex: none ;
      margin-right: 10px ;
      text-align: center ;
      font-size: 24px ;
      line-height: 46px ;
      width: 50px ;
      height: 50px ;
      border-radius: 50px ;
      border: 2px solid #ccc ;
      background:#eee;
      &:hover{
        background:#0066CC;
        color:#fff;
      }
    }
    .active {
      background: #0066CC ;
      color: #fff;
      border: 2px solid #0066CC ;
    }
    .desc {
        padding: 10px 0 ;
    }
}

.heroblue {
  background: linear-gradient(110deg, #00b2ee 60%, #007cee 60%);
  height: 320px;
  margin: -20px -20px 20px -20px;
  border-radius: 4px 4px 0 0;
  @media only screen and (max-width: 440px) {
    border-radius: 0px 0px 0 0;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  & h1 {
    text-align: center;
    color: #fff;
    font-size: 45px;
    max-width: 600px;
    @media (max-width: 415px) {
      font-size: 36px;
      padding: 20px;
    }
  }
  & a {
    text-decoration: none;
    color: #fff;
    & .signupbox {
      text-align: center;
      padding: 8px;
      font-size: 24px;
      font-weight: bold;
      background: #ff7f24;
      width: 300px;
      border-radius: 10px;
      border: 1px solid #fff;
      margin: auto;
    }
  }
}

.measurecontent {
  margin-right:41px;
    @media (max-width: 500px) {
      margin-right:0px;
    }
}

</style>

